angular.module("aerosApp")
    .factory('editOrgInfoModal', ["$uibModal", function ($uibModal) {
        function open(orgId, contactDetails, showOrgInfoDetails) {
            var modal = $uibModal.open({
                size: "md",
                resolve: {
                    orgId: function () {return orgId;},
                    contactDetails: function () {return contactDetails;},
                    showOrgInfoDetails: function () {return showOrgInfoDetails;}
            },
                templateUrl: "/static/templates/project/editOrgInfoModal.html",
                controller: "EditOrgInfoModalCtrl"
            });
            return modal.result;
        }

        return {
            open: open
        };
    }])
    .controller("EditOrgInfoModalCtrl", ["$scope", "$uibModalInstance", "aerosApi", "orgId", "contactDetails", "showOrgInfoDetails", "Notification","CountriesListService", function ($scope, $uibModalInstance, aerosApi, orgId, contactDetails, showOrgInfoDetails, Notification, CountriesListService) {

        $scope.orgInfoModel = {
            id: contactDetails.id,
            organizationId: contactDetails.organizationId,
            contactType: contactDetails.contactType,
            companyName: contactDetails.companyName,
            description: contactDetails.description,
            contactInfo: {
                address: {
                    street: contactDetails.contactInfo.address.street,
                    streetTwo: contactDetails.contactInfo.address.streetTwo,
                    city: contactDetails.contactInfo.address.city,
                    postalCode: contactDetails.contactInfo.address.postalCode,
                    state: contactDetails.contactInfo.address.state,
                    country: contactDetails.contactInfo.address.country
                },
                contactName: contactDetails.contactInfo.contactName,
                email: contactDetails.contactInfo.email,
                phone: contactDetails.contactInfo.phone
            }
        };
        $scope.countries = CountriesListService.getCountries();
        $scope.saveOrgInfo = function () {
            console.log("saveOrgInfo: ", $scope.orgInfoModel);
            aerosApi.editContact(orgId, contactDetails.id, $scope.orgInfoModel)
                .success(function () {
                    $uibModalInstance.close();
                    Notification.success("Contact information saved");
                })
                .error(function (err) {
                    Notification.error("Contact information not saved. " + err);
                });
        };
    }]);